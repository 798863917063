// Generated by Framer (d018716)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Stack, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const cycleOrder = ["WQLkyLRf1", "FqmrPsfLn", "fFVVSokzZ"];

const breakpoints = {"WQLkyLRf1": "(min-width: 1200px)", "fFVVSokzZ": "(min-width: 810px) and (max-width: 1199px)", "FqmrPsfLn": "(max-width: 809px)"}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"WQLkyLRf1": "framer-v-72rtr7", "FqmrPsfLn": "framer-v-jfpagq", "fFVVSokzZ": "framer-v-1qtri2b"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("WQLkyLRf1", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {"Desktop": "WQLkyLRf1", "Phone": "FqmrPsfLn", "Tablet": "fFVVSokzZ"};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "WQLkyLRf1", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({"FqmrPsfLn": {"WQLkyLRf1": {"transformTemplate": undefined}, "yhLucn3Vu": {"__contentWrapperStyle": {"width": "100%", "height": "100%", "padding": "40px 40px 40px 40px"}, "transformTemplate": undefined, "background": {"src": new URL("assets/4096/JTTdZra5wxa5h66fJ6xcdSrhck.jpg", import.meta.url).href, "srcSet": `${new URL("assets/512/JTTdZra5wxa5h66fJ6xcdSrhck.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/JTTdZra5wxa5h66fJ6xcdSrhck.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/JTTdZra5wxa5h66fJ6xcdSrhck.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/JTTdZra5wxa5h66fJ6xcdSrhck.jpg", import.meta.url).href} 4096w, ${new URL("assets/JTTdZra5wxa5h66fJ6xcdSrhck.jpg", import.meta.url).href} 4500w`, "pixelWidth": 4500, "pixelHeight": 2800, "fit": "fill"}}, "tspVdWSFf": {"gap": 35, "__contentWrapperStyle": {"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px", "maxWidth": 400}, "transformTemplate": undefined, "background": null}}, "fFVVSokzZ": {"WQLkyLRf1": {"transformTemplate": undefined}}}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "WQLkyLRf1", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-easVL")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-72rtr7", className)} style={{...style}} name="Desktop"  ref={ref} {...addVariantProps("WQLkyLRf1")}><SSRVariants id="yhLucn3Vu"><Stack className="framer-erum1s"   direction="vertical" distribution="space-around" alignment="center" gap={38} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "100%", "padding": "40px 40px 40px 40px"}} {...addVariantProps("yhLucn3Vu")}><SSRVariants id="tspVdWSFf"><Stack className="framer-16j9vqo"   background={null} direction="vertical" distribution="center" alignment="center" gap={45} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "100%", "padding": "0px 0px 0px 0px"}} {...addVariantProps("tspVdWSFf")}><Image className="framer-15gppo7"   background={{"src": new URL("assets/1024/BynFbGOlVgkds6PoS8CfQ6zcFU.jpeg", import.meta.url).href, "pixelWidth": 1600, "pixelHeight": 597, "intrinsicWidth": 1600, "intrinsicHeight": 597, "fit": "fit"}} alt="" {...addVariantProps("FeT945KKQ")}/></Stack></SSRVariants></Stack></SSRVariants></motion.div>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-easVL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-easVL .framer-72rtr7 { position: relative; overflow: hidden; width: 1200px; height: 800px; background-color: #ffffff; }", ".framer-easVL .framer-erum1s { position: fixed; overflow: visible; z-index: 1; width: 100%; height: 100%; left: 0px; top: 0px; flex: none; background-color: #f7f7f7; }", ".framer-easVL .framer-16j9vqo { position: relative; overflow: visible; width: 1120px; height: 300px; flex: none; }", ".framer-easVL .framer-15gppo7 { position: relative; width: 200px; height: 100%; flex: none; }", "@media (min-width: 1200px) { .framer-easVL .hidden-72rtr7 { display: none !important; } }", "@media (max-width: 809px) { .framer-easVL .hidden-jfpagq { display: none !important; } .framer-easVL .framer-72rtr7 { width: 390px; height: 800px; } .framer-easVL .framer-erum1s { width: 100%; height: 100%; right: auto; bottom: auto; left: 0px; top: 0px; flex: none; aspect-ratio: unset; } .framer-easVL .framer-16j9vqo { max-width: 400px; min-width: unset; max-height: unset; min-height: unset; width: 100%; height: min-content; right: auto; bottom: auto; left: auto; top: auto; flex: none; aspect-ratio: unset; }}", "@media (min-width: 810px) and (max-width: 1199px) { .framer-easVL .hidden-1qtri2b { display: none !important; } .framer-easVL .framer-72rtr7 { width: 810px; height: 800px; }}"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}, "FqmrPsfLn": {"layout": ["fixed", "fixed"]}, "fFVVSokzZ": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FrameraugiA20Il: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameraugiA20Il;

FrameraugiA20Il.displayName = "Generated Component";

FrameraugiA20Il.defaultProps = {"width": 1200, "height": 800};

addFonts(FrameraugiA20Il, []);